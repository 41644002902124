import { useEffect, useState, Fragment } from "react"
import { useNavigate } from "react-router-dom"
import { RingLoader } from "react-spinners"
import axios from "axios"
import { Dialog, Transition } from "@headlessui/react"
import { CheckIcon } from "@heroicons/react/24/outline"
import { Client_ID, Client_Secret, GHL_URL, BASE_URL } from "../../utils/Config"

const override = {
  borderColor: "#2317e6",
  zIndex: 31,
}

export const Redirect = () => {
  
  const navigate = useNavigate();
  const [loading] = useState(true)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const userId = Number(localStorage.getItem("id"));
    // console.log("User ID:", userId);

    const fetchUserType = async () => {
      try {
        // console.log("Fetching user type...");
        const param = { user_id: userId };
        const res = await axios.get(`${BASE_URL}/users/user-type`, { params: param });
        // console.log("User type fetched:", res.data.user_type);
        return res.data.user_type;
      } catch (error) {
        console.error("Error fetching user type:", error);
        return "Location";
      }
    };

    const handleOAuthToken = async (codeValue, userType) => {
      try {
        // console.log("Handling OAuth token with code:", codeValue, "and user type:", userType);
        const data = {
          client_id: Client_ID,
          client_secret: Client_Secret,
          grant_type: "authorization_code",
          code: codeValue,
          user_type: userType,
        };
        const config = {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        };
        const res = await axios.post(`${GHL_URL}/oauth/token`, data, config);
        // console.log("OAuth token fetched:", res.data.access_token);
        localStorage.setItem("ghl_token", res.data.access_token);
        return {
          token: res.data.access_token,
          locationId: res.data.locationId,
          companyId: res.data.companyId,
          expiresIn: res.data.expires_in,
          refreshToken: res.data.refresh_token,
        };
      } catch (err) {
        console.error("Error handling OAuth token:", err);
        return {};
      }
    };

    const handleLocationData = async (ghlData, userId, userType) => {
      if (ghlData.token) {
        // console.log("Handling location data with GHL data:", ghlData);
        try {
          const config = {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${ghlData.token}`,
              Version: "2021-07-28",
            },
          };

          if (userType === 'Location') {
            // console.log("Fetching location data for Location type user");
            const res = await axios.get(`${GHL_URL}/locations/${ghlData.locationId}`, config);
            if (res.data.location) {
              // console.log("Location data fetched:", res.data.location);
              const reqData = {
                user_id: userId,
                access_token: ghlData.token,
                expires_in: ghlData.expiresIn,
                refresh_token: ghlData.refreshToken,
                location_id: res.data.location.id,
                companyId: res.data.location.companyId,
                name: res.data.location.name,
                address: res.data.location.address,
                city: res.data.location.city,
                state: res.data.location.state,
                logoUrl: res.data.location.logoUrl,
                country: res.data.location.country,
                postalCode: res.data.location.postalCode,
                website: res.data.location.website,
                timezone: res.data.location.timezone,
                firstName: res.data.location.firstName,
                lastName: res.data.location.lastName,
                email: res.data.location.email,
                phone: res.data.location.phone,
                business: res.data.location.business,
                social: res.data.location.social,
                settings: res.data.location.settings,
              };

              // console.log("Posting location data...");
              const postRes = await axios.post(`${BASE_URL}/locations`, reqData);
              // console.log("Location data posted:", postRes.data);
              if (postRes.data.success === "success") {
                navigate(localStorage.getItem("nablJwtAccess") === null ? "/signin" : "/main/settings/account");
              }
            }
          }

          if (userType === 'Company') {
            // console.log("Fetching locations for Company type user");
            config.params = { companyId: ghlData.companyId, limit: 999 };
            const res = await axios.get(`${GHL_URL}/locations/search`, config);
            // console.log("Locations fetched:", res.data.locations);
            const reqData = {
              user_id: userId,
              companyId: ghlData.companyId,
              locations: res.data.locations,
              token: ghlData.token,
            };

            // console.log("Posting company locations data...");
            const postRes = await axios.post(`${BASE_URL}/locations/company`, reqData);
            // console.log("Company locations data posted:", postRes.data);
            if (postRes.data.msg === "success") {
              navigate(localStorage.getItem("nablJwtAccess") === null ? "/signin" : "/main/settings/account");
            }
          }
        } catch (error) {
          console.error("Error handling location data:", error);
        }
      }
    };

    const execute = async () => {
      // console.log("Executing main function...");
      const userType = await fetchUserType();
      const queryParams = new URLSearchParams(window.location.search);
      const codeValue = queryParams.get("code");
      // console.log("Query parameters:", queryParams.toString(), "Code value:", codeValue);

      let ghlData = {};
      if (codeValue) {
        ghlData = await handleOAuthToken(codeValue, userType);
      }

      await handleLocationData(ghlData, userId, userType);
    };

    execute();
  }, [navigate]);

  return (
    <div>
      <div className="m-4">
        <p className="text-gray-900 dark:text-white text-xl"></p>
      </div>
      {/* loading spinner */}
      {loading && (
        <div className="z-30 fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity opacity-100 flex items-center justify-center">
          <RingLoader
            color="#2317e6"
            loading={loading}
            cssOverride={override}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
      {/* end loading spinner */}
      {/* saved success modal */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100 dark:bg-green-300">
                      <CheckIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-100"
                      >
                        Locations data is saved successful
                      </Dialog.Title>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={() => setOpen(false)}
                    >
                      OK
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {/* end saved success modal */}
    </div>
  )
}
