import { Fragment, useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PencilSquareIcon, EyeIcon } from "@heroicons/react/24/outline";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Dialog, Transition, Combobox } from "@headlessui/react";
import { RingLoader } from "react-spinners";
import BlockDateRangeInput from "../../components/Inputs/BlockDateRangeInput.jsx";
import {
  BASE_URL,
  Client_ID,
  Client_Secret,
  GHL_URL,
  CALL_SERVICE_URL,
} from "../../utils/Config";
import axiosInstance from "../../utils/AxiosInstance";

const override = {
  borderColor: "#2317e6",
  zIndex: 31,
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ErrorMessages = ({ message }) => {
  const navigate = useNavigate();

  switch (message) {
    case "AccessToken":
      return (
        <div className="mt-5 sm:mt-6 text-center flex flex-col gap-4 items-center">
          <svg
            fill="currentColor"
            height={48}
            width={48}
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className="size-8 text-red-500"
          >
            <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM13 17h-2v-2h2v2zm0-4h-2V7h2v6z" />
          </svg>

          <span>
            Error retrieving access token. This usually means that the location
            is out of sync. Return to your account page, click{" "}
            <strong>Sync CRM</strong> and choose the appropriate Location.
          </span>

          <button
            onClick={() => {
              navigate("/main/settings/account");
            }}
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 min-w-fit"
          >
            Return to Account Page
          </button>
        </div>
      );

    case "MaxCallAllreadyExcided":
      return (
        <div className="mt-5 sm:mt-6 text-center flex flex-col gap-4 items-center">
          <svg
            fill="currentColor"
            height={48}
            width={48}
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className="size-8 text-red-500"
          >
            <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM13 17h-2v-2h2v2zm0-4h-2V7h2v6z" />
          </svg>
          You have exceeded the maximum number of saved recordings.
        </div>
      );

    case "MaxCallExcided":
      return (
        <div className="mt-5 sm:mt-6 text-center flex flex-col gap-4 items-center">
          <svg
            fill="currentColor"
            height={48}
            width={48}
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className="size-8 text-yellow-500"
          >
            <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM13 17h-2v-2h2v2zm0-4h-2V7h2v6z" />
          </svg>
          You have more recordings to sync than the limit for your account. Only
          part of the recordings were saved.
        </div>
      );

    case "Timeout":
      return (
        <div className="mt-5 sm:mt-6 text-center flex flex-col gap-4 items-center">
          <svg
            fill="currentColor"
            height={48}
            width={48}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 490.05 490.05"
            xmlSpace="preserve"
            className="size-8 text-green-500"
          >
            <path d="M418.275 418.275c95.7-95.7 95.7-250.8 0-346.5s-250.8-95.7-346.5 0-95.7 250.8 0 346.5 250.9 95.7 346.5 0zm-261.1-210.7l55.1 55.1 120.7-120.6 42.7 42.7-120.6 120.6-42.8 42.7-42.7-42.7-55.1-55.1 42.7-42.7z" />
          </svg>
          We are processing your information. In a few minutes your recordings
          will be synchronized.
        </div>
      );

    default:
      return (
        <div className="mt-5 sm:mt-6 text-center flex flex-col gap-4 items-center">
          <svg
            fill="currentColor"
            height={48}
            width={48}
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className="size-8 text-red-500"
          >
            <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM13 17h-2v-2h2v2zm0-4h-2V7h2v6z" />
          </svg>
          Error. Try again later.
        </div>
      );
  }
};

export const Admin = () => {
  const id = Number(localStorage.getItem("id"));
  const navigate = useNavigate();
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false); //user create modal
  const [openSuccessModal, setOpenSuccessModal] = useState(false); // created success modal
  const cancelButtonRef = useRef(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [userRole, setUserRole] = useState("Member");
  const [query, setQuery] = useState("");
  const [selectedLocationArray, setSelectedLocationArray] = useState([]);


  const filteredLocations =
    query === ""
      ? locations
      : locations.filter((location) => {
          return location.name.toLowerCase().includes(query.toLowerCase());
        });

  useEffect(() => {
    axiosInstance({
      method: "get",
      url: `${BASE_URL}/locations`,
      params: {
        userId: id,
      },
    })
    .then((res) => {
      const locations = res.data.locations;
      const filteredLocations = Object.values(
        locations.reduce((acc, loc) => {
          if (
            !acc[loc.name] ||
            new Date(loc.updatedAt) > new Date(acc[loc.name].updatedAt)
          ) {
            acc[loc.name] = loc;
          }
          return acc;
        }, {})
      );
      setLocations(filteredLocations);
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      setLoading(false);
    });
  }, [id]);

  useEffect(() => {
    if (selectedLocation) {
      setSelectedLocationArray((_prev) => {
        const temp = [..._prev];
        temp.push(selectedLocation);
        return temp;
      });
    }
  }, [selectedLocation]);

  const [syncRequest, setSyncRequest] = useState(false);
  const [syncRequestLocation, setSyncRequestLocation] = useState(null);

  const [locationIdOpen, setLocationIdOpen] = useState(false);
  const [locationFetchError, setLocationFetchError] = useState(null);
  const [, setLocationFetchLoading] = useState(false);

  const [locationSyncStatus, setLocationSyncStatus] = useState("Halt");

  const getAccessToken = async (refreshToken, locationId) => {
    const encodedParams = new URLSearchParams();
    encodedParams.set("client_id", Client_ID);
    encodedParams.set("client_secret", Client_Secret);
    encodedParams.set("grant_type", "refresh_token");
    encodedParams.set("refresh_token", refreshToken);
  
    const options = {
      method: "POST",
      url: `${GHL_URL}/oauth/token`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
      },
      data: encodedParams,
    };
  
    try {
      const { data } = await axiosInstance.request(options);
  
      await axiosInstance.post(`https://api-agent.yaitec.dev/update-token`, {
        locationId: locationId,
        accessToken: data.access_token,
        refreshToken: data.refresh_token,
        expiresIn: data.expires_in,
      });
  
      return data;
    } catch (err) {
      console.error(
        "Error fetching the token:",
        err.response ? err.response.data : err,
      );
      throw err;
    }
  };
  
  

  const getCallInfo = async (
    accessToken,
    locationId,
    tableId,
    startDate,
    endDate,
  ) => {
    const formData = new FormData();
    formData.append("token", accessToken);
    formData.append("location_id", locationId);
    formData.append("locations_table_id", tableId);
    formData.append("user_id", id);
    if (startDate) {
      formData.append("start_date", startDate);
    }
    if (endDate) {
      formData.append("end_date", endDate);
    }

    const options = {
      method: "POST",
      url: `${CALL_SERVICE_URL}/process_all/`,
      timeout: 1000 * 60 * 20,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
      },
      data: formData,
    };

    const { data } = await axiosInstance.request(options);
    return data;
  };

  const syncCalls = async (location, startDate, endDate) => {
    setLocationFetchLoading(true);
    setLocationIdOpen(true);
  
    try {
      setLocationSyncStatus("AccessToken");
      console.log('Dados:', location)
      console.log('location.refresh_token', location.refresh_token)
      console.log('location.location_id',  location.location_id)
      const tokenData = await getAccessToken(location.refresh_token, location.location_id);
      console.log('tokenData:', tokenData)
     
  
      setLocations(prevLocations =>
        prevLocations.map(loc =>
          loc.location_id === location.location_id
            ? { ...loc, access_token: tokenData.access_token, refresh_token: tokenData.refresh_token }
            : loc
        )
      );
  
      try {
        setLocationSyncStatus("CallInfo");
        await getCallInfo(
          tokenData.access_token,
          location.location_id,
          location.id,
          startDate,
          endDate,
        );
  
        setLocationSyncStatus("Success");
        setLocationFetchLoading(false);
      } catch (err) {
        console.error(err);
        setLocationSyncStatus("Timeout");
        setLocationFetchLoading(false);
      }
    } catch (err) {
      console.error(err);
      setLocationFetchError("AccessToken");
      setLocationSyncStatus("Error");
      setLocationFetchLoading(false);
    }
  };
  

  const handleLocationSubmit = async (e) => {
    e.preventDefault();
    const target = e.target;

    const location = locations.find((x) => x.name === target.name.value);

    if (!location) {
      setLocationFetchError("LocationNotFound");
      setLocationSyncStatus("Error");

      return;
    }

    await syncCalls(location);
    setSyncRequestLocation(location);
    setSyncRequest(true);
    target.reset();
  };

  return (
    <div>
      <div className="flex justify-between gap-4 px-4">
        <form className="flex gap-2" onSubmit={handleLocationSubmit}>
          <input
            type="text"
            name="name"
            className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 min-w-28 max-w-52 w-full dark:bg-gray-800 dark:ring-gray-700"
            placeholder="Location Name"
            required
          />
          <button
            type="submit"
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 min-w-fit"
          >
            Sync Calls
          </button>
        </form>

        <button
          type="button"
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 min-w-fit"
          onClick={() => setOpen(true)}
        >
          Add user
        </button>
      </div>
      <div className="flow-root mb-4">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full py-4 align-middle px-4">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-600">
                <thead className="bg-gray-50 dark:bg-gray-700">
                  <tr className="grid grid-cols-[auto_60px_60px_110px]">
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-100 sm:pl-6"
                    >
                      Location
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-100  justify-self-center"
                    >
                      Edit
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6 text-left text-sm text-gray-900 dark:text-gray-100  justify-self-center"
                    >
                      View
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6 text-left text-sm text-gray-900 dark:text-gray-100  justify-self-center"
                    >
                      Sync Calls
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:divide-gray-700 bg-white dark:bg-gray-800">
                  {locations.length > 0 &&
                    locations.map((location) => (
                      <tr
                        key={location.id}
                        className="grid grid-cols-[auto_60px_60px_110px]"
                      >
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-500 dark:text-gray-300 sm:pl-6">
                          {location.name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-300 justify-self-center">
                          <PencilSquareIcon
                            className="w-5 h-5 cursor-pointer dark:text-gray-300"
                            onClick={() =>
                              navigate(
                                `/main/admin/location/edit/${location.id}`,
                              )
                            }
                          />
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6  justify-self-center">
                          <EyeIcon
                            className="w-5 h-5 cursor-pointer dark:text-gray-300"
                            onClick={() =>
                              navigate(`/main/admin/location/${location.id}`)
                            }
                          />
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6  justify-self-center">
                          <button
                            onClick={() => {
                              setSyncRequestLocation(location);
                              setSyncRequest(true);
                              /* syncCalls(location); */
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="w-5 h-5 cursor-pointer dark:text-gray-300"
                            >
                              <path d="M9 20H4a2 2 0 01-2-2V5a2 2 0 012-2h3.9a2 2 0 011.69.9l.81 1.2a2 2 0 001.67.9H20a2 2 0 012 2v.5" />
                              <path d="M12 10v4h4" />
                              <path d="M12 14l1.535-1.605a5 5 0 018 1.5M22 22v-4h-4" />
                              <path d="M22 18l-1.535 1.605a5 5 0 01-8-1.5" />
                            </svg>
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* loading spinner */}
      {loading && (
        <div className="z-30 fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity opacity-100 flex items-center justify-center">
          <RingLoader
            color="#2317e6"
            loading={loading}
            cssOverride={override}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
      {/* end loading spinner */}
      {/* create user modal */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white dark:bg-gray-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6">
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-100"
                      >
                        Add new user
                      </Dialog.Title>
                      <div className="mt-4">
                        <div className="mt-2">
                          <label
                            htmlFor="firstName"
                            className="block text-sm leading-6 text-gray-900 dark:text-gray-100 text-left"
                          >
                            First name
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              id="firstName"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              onChange={(e) => setFirstName(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div className="mt-2">
                          <label
                            htmlFor="lastName"
                            className="block text-sm leading-6 text-gray-900 dark:text-gray-100 text-left"
                          >
                            Last name
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              id="lastName"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              onChange={(e) => setLastName(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div className="mt-2">
                          <label
                            htmlFor="email"
                            className="block text-sm leading-6 text-gray-900 dark:text-gray-100 text-left"
                          >
                            Email
                          </label>
                          <div className="mt-2">
                            <input
                              type="email"
                              id="email"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              onChange={(e) => setEmail(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div className="mt-2">
                          <label
                            htmlFor="role"
                            className="block text-sm leading-6 text-gray-900 dark:text-gray-100 text-left"
                          >
                            User role
                          </label>
                          <select
                            id="role"
                            name="role"
                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            defaultValue="None"
                            onChange={(e) => setUserRole(e.target.value)}
                          >
                            <option>Member</option>
                            <option>Admin</option>
                          </select>
                        </div>
                        <Combobox
                          as="div"
                          value={selectedLocation}
                          onChange={setSelectedLocation}
                        >
                          <Combobox.Label className="block text-sm leading-6 text-gray-900 dark:text-gray-100 text-left mt-2">
                            Select location{" "}
                            <span>
                              <button
                                type="button"
                                className="rounded bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                onClick={() =>
                                  setSelectedLocationArray(locations)
                                }
                              >
                                Select all locations
                              </button>
                            </span>
                          </Combobox.Label>
                          <div className="relative mt-2">
                            <Combobox.Input
                              className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              onChange={(event) => setQuery(event.target.value)}
                              displayValue={(location) => location?.name}
                            />
                            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                              <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </Combobox.Button>

                            {filteredLocations.length > 0 && (
                              <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm top-auto bottom-full">
                                {filteredLocations.map((location) => (
                                  <Combobox.Option
                                    key={location.id}
                                    value={location}
                                    className={({ active }) =>
                                      classNames(
                                        "relative cursor-default select-none py-2 pl-3 pr-9 text-left",
                                        active
                                          ? "bg-indigo-600 text-white"
                                          : "text-gray-900",
                                      )
                                    }
                                  >
                                    {({ active, selected }) => (
                                      <>
                                        <span
                                          className={classNames(
                                            "block truncate",
                                            selected && "font-semibold",
                                          )}
                                        >
                                          {location.name}
                                        </span>

                                        {selected && (
                                          <span
                                            className={classNames(
                                              "absolute inset-y-0 right-0 flex items-center pr-4",
                                              active
                                                ? "text-white"
                                                : "text-indigo-600",
                                            )}
                                          >
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                            The records were synced! 09:57
                                          </span>
                                        )}
                                      </>
                                    )}
                                  </Combobox.Option>
                                ))}
                              </Combobox.Options>
                            )}
                          </div>
                        </Combobox>
                        <div className="mt-2 text-left">
                          {selectedLocationArray.length > 0 &&
                            selectedLocationArray.map((location) => (
                              <span
                                className="inline-flex items-center gap-x-0.5 rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700 mr-2"
                                key={location.id}
                              >
                                {location.name}
                                <button
                                  type="button"
                                  className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-green-600/20"
                                  onClick={() => {
                                    setSelectedLocationArray((_prev) => {
                                      const tmp = [..._prev];
                                      const result = tmp.filter(
                                        (item) => item.id !== location.id,
                                      );
                                      return result;
                                    });
                                  }}
                                >
                                  <span className="sr-only">Remove</span>
                                  <svg
                                    viewBox="0 0 14 14"
                                    className="h-3.5 w-3.5 stroke-green-800/50 group-hover:stroke-green-800/75"
                                  >
                                    <path d="M4 4l6 6m0-6l-6 6" />
                                  </svg>
                                  <span className="absolute -inset-1" />
                                </button>
                              </span>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                      onClick={() => {
                        const data = {
                          user_id: id,
                          firstName: firstName,
                          lastName: lastName,
                          email: email,
                          locations: selectedLocationArray,
                          role: userRole,
                        };
                        if (
                          firstName === "" ||
                          lastName === "" ||
                          email === ""
                        ) {
                          return;
                        }
                        axiosInstance({
                          method: "post",
                          url: `${BASE_URL}/users/add-user`,
                          data: data,
                        })
                          .then((res) => {
                            res.data.msg === "success" &&
                              setOpenSuccessModal(true);
                          })
                          .catch((err) => {
                            console.log(err);
                          })
                          .finally(() => {
                            setOpen(false);
                          });
                      }}
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white dark:bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {/* end create user modal */}
      {/* saved success modal */}
      <Transition.Root show={openSuccessModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={setOpenSuccessModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-100"
                      >
                        User created successful
                      </Dialog.Title>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={() => setOpenSuccessModal(false)}
                    >
                      OK
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {/* end saved success modal */}

      <SyncModal
        locationIdOpen={locationIdOpen}
        setLocationIdOpen={setLocationIdOpen}
        locationSyncStatus={locationSyncStatus}
        locationFetchError={locationFetchError}
      />

      <SyncRequestModal
        syncRequest={syncRequest}
        setSyncRequest={setSyncRequest}
        syncRequestLocation={syncRequestLocation}
        syncCallsCB={syncCalls}
      />
    </div>
  );
};

function SyncModal({
  locationIdOpen,
  setLocationIdOpen,
  locationSyncStatus,
  locationFetchError,
}) {
  return (
    <Transition.Root show={locationIdOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setLocationIdOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div className="dark:text-gray-200">
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-100"
                    >
                      Sync Calls
                    </Dialog.Title>
                  </div>

                  {locationSyncStatus === "Halt" ? (
                    <div className="mt-5 sm:mt-6 text-center flex flex-col gap-4 items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="text-indigo-600 animate-spin"
                      >
                        <path d="M21 12a9 9 0 1 1-6.219-8.56" />
                      </svg>
                      Finding Location
                    </div>
                  ) : locationSyncStatus === "AccessToken" ? (
                    <div className="mt-5 sm:mt-6 text-center flex flex-col gap-4 items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="text-indigo-600 animate-spin"
                      >
                        <path d="M21 12a9 9 0 1 1-6.219-8.56" />
                      </svg>
                      Fetching Access Token
                    </div>
                  ) : locationSyncStatus === "CallInfo" ? (
                    <div className="mt-5 sm:mt-6 text-cente flex flex-col gap-4 items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="text-indigo-600 animate-spin"
                      >
                        <path d="M21 12a9 9 0 1 1-6.219-8.56" />
                      </svg>
                      Sync Records
                    </div>
                  ) : locationSyncStatus === "Success" ? (
                    <div className="mt-5 sm:mt-6 text-center flex flex-col gap-4 items-center">
                      <svg
                        fill="currentColor"
                        height={48}
                        width={48}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 490.05 490.05"
                        xmlSpace="preserve"
                        className="size-8 text-green-500"
                      >
                        <path d="M418.275 418.275c95.7-95.7 95.7-250.8 0-346.5s-250.8-95.7-346.5 0-95.7 250.8 0 346.5 250.9 95.7 346.5 0zm-261.1-210.7l55.1 55.1 120.7-120.6 42.7 42.7-120.6 120.6-42.8 42.7-42.7-42.7-55.1-55.1 42.7-42.7z" />
                      </svg>
                      The records were synced!
                    </div>
                  ) : locationSyncStatus === "Timeout" ? (
                    <div className="mt-5 sm:mt-6 text-center flex flex-col gap-4 items-center">
                      <svg
                        fill="currentColor"
                        height={48}
                        width={48}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 490.05 490.05"
                        xmlSpace="preserve"
                        className="size-8 text-green-500"
                      >
                        <path d="M418.275 418.275c95.7-95.7 95.7-250.8 0-346.5s-250.8-95.7-346.5 0-95.7 250.8 0 346.5 250.9 95.7 346.5 0zm-261.1-210.7l55.1 55.1 120.7-120.6 42.7 42.7-120.6 120.6-42.8 42.7-42.7-42.7-55.1-55.1 42.7-42.7z" />
                      </svg>
                      We are processing your information. In a few minutes your
                      recordings will be synchronized.
                    </div>
                  ) : (
                    <>
                      <ErrorMessages message={locationFetchError} />
                    </>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function SyncRequestModal({
  syncRequest,
  setSyncRequest,
  syncRequestLocation,
  syncCallsCB,
}) {
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  return (
    <Transition.Root show={syncRequest} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setSyncRequest}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-100"
                    >
                      Sync Calls
                    </Dialog.Title>
                  </div>

                  <div className="mt-5 sm:mt-6 text-center flex flex-col gap-4">
                    <p className="text-center dark:text-gray-200">
                      Select a time interval for call retrieval or leave empty
                      to sync the last 30 days.
                    </p>
                    <BlockDateRangeInput
                      onChange={(data) => {
                        setDateRange(
                          data
                            ? {
                                startDate: data.startDate.getTime(),
                                endDate: data.endDate.getTime(),
                              }
                            : { startDate: null, endDate: null },
                        );
                      }}
                      pickerOpts={{ maxDate: new Date() }}
                    />
                    <button
                      className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 min-w-fit"
                      onClick={() => {
                        setSyncRequest(false);
                        syncCallsCB(
                          syncRequestLocation,
                          dateRange.startDate,
                          dateRange.endDate,
                        );
                      }}
                    >
                      Sync Calls
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
